import { setDefaultOptions } from "date-fns";
import { enGB, pl } from "date-fns/locale";
import { GetServerSideProps } from "next";
import { getServerSideTranslations } from "../../lib/i18n";
import LoginView, { getServerSideLoginViewProps } from "../../views/LoginView/LoginView";

export default LoginView;

export const getServerSideProps: GetServerSideProps = async context => {
  setDefaultOptions({ locale: context.locale === "en" ? enGB : pl });
  const localeProps = await getServerSideTranslations(context.locale ?? "pl");
  const serverSideApi = (await getServerSideLoginViewProps(context)) as any;
  return {
    props: {
      ...localeProps,
      ...serverSideApi.props
    }
  };
};
