import { IModelBuilder } from "@new-wars/core/src/mvvm/ModelBuilder";
import { LoginViewModel } from "./LoginViewModel";

export type LoginViewApiData = {};

export class LoginViewModelBuilder implements IModelBuilder<LoginViewModel> {
  build(apiData: LoginViewApiData): LoginViewModel {
    return new LoginViewModel(apiData);
  }

  async loadApiData() {
    const apiData = {};

    return apiData;
  }
}
