import { runInAction } from "mobx";
import { Command } from "./commands/Command/Command";

export type ConstructorOf<T> = new (p1?: any, p2?: any, p3?: any, p4?: any) => T;

export interface IProperty<T> {
  get: () => T;
  set: (v: T) => void;
}

export function property<T, K extends keyof T>(model: T, propertyName: K) {
  return {
    get: () => model[propertyName],
    set: (value: T[K]) => {
      runInAction(() => {
        model[propertyName] = value;
      });
    }
  };
}

export function bindTo<T>(property: IProperty<T>) {
  return {
    value: property.get(),
    onChange: (value: T) => property.set(value)
  };
}

export function bindProperty<T, K extends keyof T>(model: T, propertyName: K) {
  return bindTo(property(model, propertyName));
}

export function bindToCommand(command: Command<any>, callback: () => void = async () => {}, eventName?: string) {
  return {
    [eventName || "onClick"]: async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const result = await command.execute();
      await callback();
      return result;
    },
    isDisabled: !command.isEnabled
  };
}
