import { authorizationModel, Model } from "@new-wars/core";
import { makeObservable } from "mobx";
import { LoginViewApiData } from "./LoginViewModelBuilder";

export class LoginViewModel extends Model {
  loginFormModel = authorizationModel;
  constructor(apiData: LoginViewApiData) {
    super(apiData);

    makeObservable(this, {});
  }
}
