import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue
} from "@chakra-ui/react";
import { Blur, CustomInput, withModel } from "@new-wars/common";
import { bindProperty, bindToCommand } from "@new-wars/core/src/mvvm/core";
import { observer } from "mobx-react";
import { GetServerSideProps, NextPage } from "next";
import { getSession, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { GiSpaceNeedle } from "react-icons/gi";
import { LoginViewModel } from "./LoginViewModel";
import { LoginViewModelBuilder } from "./LoginViewModelBuilder";

type LoginViewProps = {
  model: LoginViewModel;
};
const avatars = [
  {
    icon: <GiSpaceNeedle />
  },
  {
    icon: <GiSpaceNeedle />
  },
  {
    icon: <GiSpaceNeedle />
  },
  {
    icon: <GiSpaceNeedle />
  },
  {
    icon: <GiSpaceNeedle />
  }
];
const LoginView: NextPage<LoginViewProps> = observer(({ model }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { status } = useSession();
  const router = useRouter();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (status === "authenticated") router.push("/dashboard");
  }, [status, router]);

  return (
    <Box position={"relative"}>
      <Head>
        <title>Logowanie</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading lineHeight={1.1} fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}>
            Space{" "}
            <Text as={"span"} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
              &
            </Text>{" "}
            Wars
          </Heading>
          <Stack direction={"row"} spacing={4} align={"center"}>
            <AvatarGroup>
              {avatars.map((avatar, index) => (
                <Avatar
                  key={index}
                  icon={avatar.icon}
                  size={useBreakpointValue({ base: "md", md: "lg" })}
                  position={"relative"}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: "full",
                    height: "full",
                    rounded: "full",
                    transform: "scale(1.125)",
                    bgGradient: "linear(to-bl, red.400,pink.400)",
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0
                  }}
                />
              ))}
            </AvatarGroup>
            <Text fontFamily={"heading"} fontSize={{ base: "4xl", md: "6xl" }}>
              +
            </Text>
            <Flex
              align={"center"}
              justify={"center"}
              fontFamily={"heading"}
              fontSize={{ base: "sm", md: "lg" }}
              bg={"gray.800"}
              color={"white"}
              rounded={"full"}
              width={useBreakpointValue({ base: "44px", md: "60px" })}
              height={useBreakpointValue({ base: "44px", md: "60px" })}
              position={"relative"}
              _before={{
                content: '""',
                width: "full",
                height: "full",
                rounded: "full",
                transform: "scale(1.125)",
                bgGradient: "linear(to-bl, orange.400,yellow.400)",
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0
              }}
            >
              YOU
            </Flex>
          </Stack>
        </Stack>
        <Stack bg={"gray.50"} rounded={"xl"} p={{ base: 4, sm: 6, md: 8 }} spacing={{ base: 8 }} maxW={{ lg: "lg" }}>
          <Stack spacing={4}>
            <Heading color={"gray.800"} lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}>
              Join our Space Okręt podwodny
              <Text as={"span"} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              Create new imperium. New era of Space Wars.
            </Text>
          </Stack>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              <CustomInput
                placeholder={t("email") as string}
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500"
                }}
                {...bindProperty(model.loginFormModel, "email")}
              />
              <InputGroup>
                <CustomInput
                  placeholder={t("password") as string}
                  bg={"gray.100"}
                  border={0}
                  type={showPassword ? "text" : "password"}
                  color={"gray.500"}
                  _placeholder={{
                    color: "gray.500"
                  }}
                  {...bindProperty(model.loginFormModel, "password")}
                />
                <InputRightElement h={"full"}>
                  <Button variant={"ghost"} onClick={() => setShowPassword(showPassword => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl"
              }}
              {...bindToCommand(model.loginFormModel.logIn)}
            >
              {t("login")}
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur position={"absolute"} top={-10} left={-10} style={{ filter: "blur(70px)" }} />
    </Box>
  );
});

export const getServerSideLoginViewProps: GetServerSideProps = async context => {
  // Fetch data from external API
  // const apiData = await new LoginViewModelBuilder().loadApiData();
  // Pass data to the page via props
  const apiData = { test: {} };
  return { props: { apiData, session: await getSession(context) } };
};

export default withModel<LoginViewModel, LoginViewModelBuilder>(LoginView, LoginViewModelBuilder);
